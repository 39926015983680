/* Form.css */
.form-container {
    background-color: #f8f8f8;
    text-align: center;
    padding: 10px;
    margin: auto;
    width: 60%;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position:absolute;
    left: 19%;
    top: 20%;
    padding-left: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
  }
  
  h2 {
    color: #333;
  }
  
  p {
    color: #555;
    margin-bottom: 20px;
  }
  
  .contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .icon {
    height: 30px;
    margin-right: 10px;
  }
  
  .divider {
    border: 1px solid #ccc;
    margin: 30px 0;
  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    /* height: 40px; */
  }
  
  .input,
  .select-input {
    width: 48%;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
  }
  
  .select-input {
    background-color: #fff;
  }
  .input{
    height: 20px;
    width: 46%;
  }
  
  .input-group .input:last-child {
    margin-left: 20px;
  }
  